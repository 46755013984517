define("discourse/plugins/discourse-mailing-list-user-preferences/discourse/templates/connectors/user-preferences-emails/mailing-lists-preferences-emails", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group mailing-list-notifications">
      <label class="control-label">{{i18n 'user.mailing_lists'}}</label>
      <div class="instructions">{{{i18n 'user.mailing_lists_instructions'}}}</div>
  
      {{category-checkbox-list selection=model.watchedCategories blacklist=selectedCategories}}
  </div>
  
  */
  {
    "id": "KM16YEle",
    "block": "[[[10,0],[14,0,\"control-group mailing-list-notifications\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"user.mailing_lists\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[2,[28,[37,0],[\"user.mailing_lists_instructions\"],null]],[13],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"selection\",\"blacklist\"],[[30,0,[\"model\",\"watchedCategories\"]],[30,0,[\"selectedCategories\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-mailing-list-user-preferences/discourse/templates/connectors/user-preferences-emails/mailing-lists-preferences-emails.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `selectedCategories` property path was used in the `discourse/plugins/discourse-mailing-list-user-preferences/discourse/templates/connectors/user-preferences-emails/mailing-lists-preferences-emails.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.selectedCategories}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"category-checkbox-list\"]]",
    "moduleName": "discourse/plugins/discourse-mailing-list-user-preferences/discourse/templates/connectors/user-preferences-emails/mailing-lists-preferences-emails.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});